// This is our FB pixel
// Used to track page view and form submits on all hosted landing pages
export const FB_PIXEL_ID = "721514255128765";
export const FB_PIXEL_ID_TBH = "134122685490265";
export const FB_PIXEL_ID_AB = "1014439665646317";
export const FB_PIXEL_ID_Chubby = "582887953575191";
export const FB_PIXEL_ID_AKUA = "410589156441264";
export const FB_PIXEL_ID_TCC = "2486543311411798";
export const FB_PIXEL_ID_SAV = "3313545125631660";
export const FB_PIXEL_ID_BOBA = "1454399895013092";
export const FB_PIXEL_ID_AVEC = "249010086174044";
export const FB_PIXEL_ID_FIFTH = "2644307719158208";
export const FB_PIXEL_ID_BRAMI = "1812816798947613";
export const FB_PIXEL_ID_BUNDLEJOY = "508531210829679";
export const FB_PIXEL_ID_GATSBY = "838760437047052";
export const FB_PIXEL_ID_DIK = "397751209177299";
export const FB_PIXEL_ID_NATTY = "475993686475037";
export const FB_PIXEL_ID_WildWonder = "341428010168919";
export const FB_PIXEL_ID_HOPWTR = "561692991188158";
export const FB_PIXEL_ID_STUR = "663187780506905";
export const FB_PIXEL_ID_NAG = "4469867766402304";
export const FB_PIXEL_ID_BELLI = "1366176567104141";
export const FB_PIXEL_ID_FREESTYLE = "1378030732661805";
export const FB_PIXEL_ID_ECLIPSE = "1452379224914738";
export const FB_PIXEL_ID_CHKP = "706773597941861";
export const FB_PIXEL_ID_HD = "179436010752358";
export const FB_PIXEL_ID_NUTS = "2295567067331597";
export const FB_PIXEL_ID_Truff = "250228238820640";
export const FB_PIXEL_ID_Volley = "588312168474086";
export const FB_PIXEL_ID_POPBOTTLE = "570597503889835";
export const FB_PIXEL_ID_UNEFEMME = "565590328197767";
export const FB_PIXEL_ID_GOOEY = "491026931972759";
export const FB_PIXEL_ID_GHIA = "179175560181739";
export const FB_PIXEL_ID_CP = "3713296982015341";
export const FB_PIXEL_ID_RUBY = "770984314441624";
export const FB_PIXEL_ID_NEURO = "1082877448528540";
export const FB_PIXEL_ID_HEALTHYBUD = "220980990531273";
export const FB_PIXEL_ID_MUDDYBITES = "750003812047277";
export const FB_PIXEL_ID_LILY = "297972258137570";
export const FB_PIXEL_ID_LUND = "169958741660248";
export const FB_PIXEL_ID_RGBW = "1431623964318124";
export const FB_PIXEL_ID_ROWDY = "322336842139011";
export const FB_PIXEL_ID_DRD = "251150204276028";
export const FB_PIXEL_ID_SHAKA = "721514255128765";
export const FB_PIXEL_ID_SHAKA_BOGO = "2218366378451632";
export const FB_PIXEL_ID_GT = "2840041369615576";
export const FB_PIXEL_ID_ENERGIZER = "781035252007621";
export const FB_PIXEL_ID_GUTZY = "1945070739133931";
export const FB_PIXEL_ID_WHOLE_EARTH_50 = "4444885942230500";
export const FB_PIXEL_ID_WILDE = "240328326342061";
export const FB_PIXEL_ID_SEA_MONSTERS = "6423009777768340";
export const FB_PIXEL_ID_QUANTUM_ENERGY = "303154635523571";
export const FB_PIXEL_ID_RIGHT_COAST_5 = "315269144212695";
export const FB_PIXEL_ID_CHKP_BOGO = "706773597941861";
export const FB_PIXEL_ID_DELACALLE_WEGMANS = "2636104453219169";
export const FB_PIXEL_ID_HELLA_COCKTAIL_BOGO = "187352669306904";
export const FB_PIXEL_ID_SUPER_COFFEE_NATL_COFF_DAY = "832188798396897";
export const FB_PIXEL_ID_QUIP_WALMART_10 = "1729444483949162";
export const FB_PIXEL_ID_PITAYA_FOODS_WALMART = "350951415075388";
export const FB_PIXEL_ID_COCOMELS_BOGO = "364250088213588";
export const FB_PIXEL_ID_TRADITIONAL_MEDICINALS = "1401978703206678";
export const FB_PIXEL_ID_OLIVER_WINERY = "652507931576062";
export const FB_PIXEL_ID_JINX_4 = "478056506459080";
export const FB_PIXEL_ID_COMETEER = "413198056104490";
export const FB_PIXEL_ID_ALPHA_FOODS = "681827863651742";
export const FB_PIXEL_ID_BLUELAND = "1039255249596221";
export const FB_PIXEL_ID_CLEVELAND_KITCHEN = "2282908841957103";
export const FB_PIXEL_ID_NOT_JUST_CO = "779203257337527";
export const FB_PIXEL_ID_THE_PATCH_BRAND = "806942150008544";
export const FB_PIXEL_ID_COZUMI = "588285112939135";
export const FB_PIXEL_ID_CLEAN_CULT = "328114733204458";
export const FB_PIXEL_ID_A_BETTER_TREAT = "2639886312958377";
export const FB_PIXEL_ID_TEA_DROPS = "178369258588141";
export const FB_PIXEL_ID_SUNNIE = "401831527884888";
export const FB_PIXEL_ID_MODERN_MAN = "841888914054263";
export const FB_PIXEL_ID_BACK_TO_NATURE = "1736324150218898";
export const FB_PIXEL_ID_JESSICAS_NATURALS = "675022833351574";
export const FB_PIXEL_ID_RUDIS = "880564533455041";
export const FB_PIXEL_ID_MINOR_FIGURES = "368989028818487";
export const FB_PIXEL_ID_URBAN_REMEDY = "2514191672197377";
export const FB_PIXEL_ID_ATHENA_CLUB = "858609340985165";
export const FB_PIXEL_ID_LONGBOTTOM = "921379765900073";
export const FB_PIXEL_ID_BLOOM_NUTRITION = "415153220821918";
export const FB_PIXEL_ID_HEALERS_PET_CARE = "651355205657965";
export const FB_PIXEL_ID_WET_HYDRATION = "174672148644064";
export const FB_PIXEL_ID_THATS_IT = "370389260569169";
export const FB_PIXEL_ID_EGUNSI_FOODS = "121437651979491";
export const FB_PIXEL_ID_JUST_FOOD_FOR_DOGS = "110883939580330";
export const FB_PIXEL_ID_SHAMELESS_SNACKS = "468196808410917";
export const FB_PIXEL_ID_YUMMERS = "1698357847224618";
export const FB_PIXEL_ID_SWEETUMS = "1119331785467211";
export const FB_PIXEL_ID_SLATE = "937836696364998";
export const FB_PIXEL_ID_FEEL_GOOD_FOOD = "365346437168384";
export const FB_PIXEL_ID_VAE = "688095281791124";
export const FB_PIXEL_ID_VOYAGE = "428612555483903";
export const FB_PIXEL_ID_GLOBOWL = "534946281302339";
export const FB_PIXEL_ID_WOW_BAO = "374050078359296";
export const FB_PIXEL_ID_COPPER_COW = "352737540584825";
export const FB_PIXEL_ID_EARTH_BREEZE = "596488994452999";
export const FB_PIXEL_ID_HAPPY_WOLF = "2000224327019915";
export const FB_PIXEL_ID_RISE_AND_PUFF = "6167245613360134";
export const FB_PIXEL_ID_CHURN = "462426874713288";
export const FB_PIXEL_ID_MAMAS_BISCUITS = "343173082945082";
export const FB_PIXEL_ID_TAMPON_TRIBE = "1667332690180020";
export const FB_PIXEL_ID_STELLAR_EATS = "254003475987528";
export const FB_PIXEL_ID_HOMIAH = "5110915802329000";
export const FB_PIXEL_ID_POURRI = "528764760636492";
export const FB_PIXEL_ID_JETSON = "422852821601457";
export const FB_PIXEL_ID_MAGIC_MIND = "589703626120432";
export const FB_PIXEL_ID_CEREBELLY = "2372565099494442";
export const FB_PIXEL_ID_ACTUAL_VEGGIES = "446082516935663";
export const FB_PIXEL_ID_LIVWELL = "916585339817778";
export const FB_PIXEL_ID_JUNI = "821911575360389";
export const FB_PIXEL_ID_GOOD_PLANET = "1219355678507169";
export const FB_PIXEL_ID_CASATERA = "651660096862447";
export const FB_PIXEL_ID_BLUE_ZONES_KITCHEN = "958087302396073";
export const FB_PIXEL_ID_EVERY_BODY_EAT = "719566212271968";
export const FB_PIXEL_ID_SKINNY_DIPPED = "1992973177583739";
export const FB_PIXEL_ID_MEZCLA = "631657880951601";
export const FB_PIXEL_ID_AGRO = "417665071010319";
export const FB_PIXEL_ID_FISHWIFE = "534024614942695";
export const FB_PIXEL_ID_BLUME = "63011074134348";
export const FB_PIXEL_ID_CURIE = "595275707493839";
export const FB_PIXEL_ID_MAYAWELL = "707831189683864";
export const FB_PIXEL_ID_GOODLES = "284985970030171";
export const FB_PIXEL_ID_LIQUID_REMEDY = "355515713399893";
export const FB_PIXEL_ID_ULTIMA = "616751521822102";
export const FB_PIXEL_ID_GORGIE = "540487494776094";
export const FB_PIXEL_ID_VITACOCO = "1828852860686138";
export const FB_PIXEL_ID_DIANAS_BANANAS = "481277191264645";
export const FB_PIXEL_ID_MALK = "223958231543514";
export const FB_PIXEL_ID_MALK_CORE = "1386616138662843";
export const FB_PIXEL_ID_MALK_CORE2 = "223958231543514";
export const FB_PIXEL_ID_MALK_CORE_CK2 = "557809253340454";
export const FB_PIXEL_ID_LUCKY_BEV_CONTEST = "1712201519248829";
export const FB_PIXEL_ID_STEAZ = "498791311274004";
export const FB_PIXEL_ID_BRAINIAC = "341929346696201";

export const pageview = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - this is a global function
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
//@ts-expect-error Not sure of the types yet
export const event = (name, options = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - this is a global function
  window.fbq("track", name, options);
};
